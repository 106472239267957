<template>
  <form class="chat-gpt" @submit.prevent="generate()">
    <div class="wrapper">
      <div class="title font-md">
        <b v-if="modalParams.page === 'apply'">챗GPT로 초안 생성하기</b>
        <b v-else>챗GPT로 프로젝트 시작하기</b>
        <span class="badge font-xs">Beta</span>
      </div>
      <div class="alert alert-info py-3 font-sm" v-if="state.generate.text">
        <span>{{ state.generate.text }}</span>
        <span class="ml-2">{{ state.generate.time }}</span>
      </div>
      <div class="editor" v-if="state.generate.finished">
        <div class="alert alert-info font-sm py-3">
          <span>다음과 같이 프로젝트의 초안을 작성하였습니다. 진행하시려면 </span>
          <b v-if="modalParams.page === 'apply'">적용하기</b>
          <b v-else>시작하기</b>
          <span> 버튼을 눌러주세요.</span>
        </div>
        <TinyEditor :value.sync="state.generate.content" :height="620" ref="editorRef"/>
      </div>
      <template v-else>
        <div class="intro">
          <div class="mb-1">프로젝트를 작성하기 어려우시다면 챗GPT를 이용해보세요.</div>
          <div class="mb-1">오마이컴퍼니에서는 챗GPT를 활용하여 프로젝트 설계가 가능합니다.</div>
          <div class="mb-1">기본 텍스트를 작성하고 분야를 선택하시면 보다 쉽고 빠르게 프로젝트를 완성하실 수 있습니다.</div>
          <b>이제 챗GPT로 간편하게 오마이컴퍼니 펀딩에 도전하세요!</b>
        </div>
        <div class="form">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label class="subject font-sm" :for="`${component.name}Title`">
                  <span class="color-purple">* </span>
                  <span>펀딩 제목은 뭘로 하면 좋을까요?</span>
                </label>
                <input :id="`${component.name}Title`" class="form-control border-focus-purple" placeholder="ex) 자연에서 온 그래놀라를 경험해보세요!" v-model="state.form.title" @input="setForm($event, 'title')" max="100" :disabled="computedFormDiff"/>
              </div>
              <div class="form-group mt-4">
                <label class="subject font-sm" :for="`${component.name}Category`">
                  <span class="color-purple">* </span>
                  <span>펀딩 카테고리는 어떤 것이 적합할까요?</span>
                </label>
                <select :id="`${component.name}Category`" class="border-focus-purple form-control" v-model="state.form.category" @change="setPrompt()" :disabled="computedFormDiff">
                  <option :value="''">펀딩 카테고리를 선택해주세요.</option>
                  <option v-for="(c,idx) in state.categories" :key="idx" :value="c.codeId">{{ c.codeIdName }}</option>
                </select>
              </div>
              <div class="form-group target mt-4">
                <label class="subject font-sm" :for="`${component.name}Target`">
                  <span class="color-purple">* </span>
                  <span>이 펀딩을 더 좋아할 사람은 누구일까요?</span>
                </label>
                <input :id="`${component.name}Target`" class="form-control border-focus-purple" placeholder="ex) 그래놀라를 좋아하는 30대, 리사이클링에 관심이 많은 주부" max="100" v-model="state.form.target" @input="setForm($event, 'target')"
                       :disabled="computedFormDiff"/>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="prompt" v-if="computedFormIn">
                <label class="subject font-sm" :for="`${component.name}Prompt`">요청 내용</label>
                <button class="btn" type="button" @click="setPrompt()" v-if="computedFormDiff">초기화</button>
                <div class="wrapper">
                <textarea :id="`${component.name}Prompt`" class="form-control border-focus-purple font-sm" placeholder="프로젝트 정보를 입력해주세요."
                          v-model="state.form.prompt"></textarea>
                </div>
              </div>
              <div class="image" v-else>
                <img class="meeting" src="/assets/img/modal.chat-gpt.meeting.gif"/>
                <img class="robot" src="/assets/img/modal.chat-gpt.robot.png"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="act">
      <div class="row" v-if="state.generate.finished">
        <div class="col pr-0">
          <button class="btn btn-purple btn-block font-sm" type="button" @click="apply()" v-if="modalParams.page === 'apply'">적용하기</button>
          <button class="btn btn-purple btn-block font-sm" type="button" @click="start()" v-else>시작하기</button>
        </div>
        <div class="col pl-0">
          <button class="btn btn-secondary btn-block font-sm" type="button" @click="copy()">복사하기</button>
        </div>
      </div>
      <button class="btn btn-purple btn-block font-sm" type="submit" v-else>생성하기</button>
    </div>
  </form>
</template>

<script>
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import TinyEditor from "@/components/TinyEditor";
import router from "@/scripts/router";

function Component(initialize) {
  this.name = "modalChatGPT";
  this.initialize = initialize;
}

export default defineComponent({
  components: {TinyEditor, Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage");
      }

      http.get("/api/reward/categories").then(({data}) => {
        state.categories = data.body;
        state.form.prompt = computedPrompt.value;
      });

      if (modalParams.page === "apply") {
        state.form.title = modalParams.title || "";
        state.form.category = modalParams.category || "";
      }

      store.commit("tuneModal", {component, size: "lg"});
    });

    const state = reactive({
      loaded: false,
      categories: [],
      generate: {
        finished: false,
        content: "",
        timeout: null,
        interval: 0,
        minutes: 3,
        time: "",
        text: ""
      },
      form: {
        title: "",
        category: "",
        target: "",
        prompt: "",
      }
    });

    const modalParams = store.getters.modalParams(component);
    const editorRef = ref();

    const computedPrompt = computed(() => {
      if (!state.form.title || !state.form.category || !state.form.target) {
        return "";
      }

      const category = state.categories.find((c) => c.codeId === state.form.category)?.codeIdName;

      return [
        `${category} 분야의 크라우드펀딩 내용을 작성해주세요.`,
        `펀딩 제목은 "${state.form.title.trim()}" ${state.form.title.trim()}입니다.`,
        `그리고 "${state.form.target.trim()}"${lib.isEndsWithConsonant(state.form.target.trim()) ? "을" : "를"} 대상으로 홍보하고 싶어요.`,
        "밝고 따뜻한 느낌이 드는 단어를 많이 사용해주세요.",
        "내용 길이는 1000 byte 이상으로 하고, 3개의 단락으로 구분해주세요.",
      ].join("\n");
    });

    const computedFormIn = computed(() => {
      return false;
      // return state.form.title && state.form.category && state.form.target;
    });

    const computedFormDiff = computed(() => {
      return state.form.prompt !== computedPrompt.value;
    });

    const setForm = (e, key) => {
      state.form[key] = e.target.value;
      setPrompt();
    };

    const setPrompt = () => {
      state.form.prompt = computedPrompt.value;
    };

    const start = () => {
      const args1 = {
        partnershipFlag: "N",
        simulationFlag: "N"
      };

      http.post("/api/reward/projects", args1).then((res1) => {
        const projectSeq = res1.data.body.projectSeq;

        http.get(`/api/reward/projects/${projectSeq}/apply`, null, {loading: true}).then((res2) => {
          const params = res2.data.body;
          params.projectName = state.form.title;
          params.projectCate = state.form.category;
          params.projectInfo = state.generate.content;

          http.put(`/api/reward/projects/${projectSeq}/apply`, params).then(() => {
            router.push({path: `/apply/${projectSeq}`});
          });
        });
      });
    };

    const copy = () => {
      window.tinymce.execCommand("selectAll", true, "id_text");
      window.tinymce.execCommand("copy", true, "id_text");
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const apply = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {
            modal,
            params: {
              projectName: state.form.title,
              projectCate: state.form.category,
              projectInfo: state.generate.content,
            }
          });
        }
      });
    };

    const generate = () => {
      const args = lib.getRenewed(state.form);
      args.category = state.categories.find((c) => c.codeId === args.category)?.codeIdName;

      if (!state.form.title?.trim()) {
        document.getElementById(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "펀딩 소개 내용을 입력해주세요.");
      } else if (!state.form.category) {
        document.getElementById(`${component.name}Category`)?.focus();
        return store.commit("setSwingMessage", "프로젝트 분야를 선택해주세요.");
      } else if (!state.form.target?.trim()) {
        document.getElementById(`${component.name}Target`)?.focus();
        return store.commit("setSwingMessage", "프로젝트 타겟팅을 입력해주세요.");
      }

      state.generate.timeout = setTimeout(() => {
        if (!state.loaded) {
          let seconds = 0;
          state.generate.text = `챗GPT가 프로젝트 초안을 작성 중입니다. 최대 ${state.generate.minutes}분까지 소요될 수 있습니다.`;

          clearInterval(state.generate.interval);
          state.generate.interval = setInterval(() => {
            const minutes = Math.floor(++seconds / 60);
            state.generate.time = `${lib.getNumberWithPadding(minutes)}:${lib.getNumberWithPadding(seconds - 60 * minutes)}`;
          }, 1000);
        }
      }, 3000);

      state.loaded = false;
      http.post("/api/chatgpt", {topic: "project", prompt: state.form.prompt}).then(({data}) => {
        state.loaded = true;
        clearTimeout(state.generate.timeout);
        clearInterval(state.generate.interval);

        if (data.code === "200 OK") {
          state.generate.text = "";

          if (!data.body) {
            return store.commit("setSwingMessage", "오류가 있습니다. 관리자에게 문의해주세요.");
          }

          state.generate.content = data.body.trim();
          state.generate.finished = true;
        } else {
          store.commit("setSwingMessage", data.message);
        }
      });
    };

    onMounted(() => {
      document.getElementById(`${component.name}Title`)?.focus();
    });

    onUnmounted(() => {
      clearInterval(state.generate.interval);
    });

    return {component, state, modalParams, editorRef, computedPrompt, computedFormIn, computedFormDiff, setForm, setPrompt, start, copy, apply, generate};
  },
});
</script>

<style lang="scss" scoped>
.chat-gpt {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px25;

      b {
        vertical-align: middle;
      }

      span {
        vertical-align: middle;

        &.badge {
          vertical-align: bottom;
          padding-left: 0;
          position: relative;
          margin-left: 5px;
          top: -1px;
        }
      }
    }

    .intro {
      padding: 20px;
      border: 1px solid #eee;
      background: #f7f7f7;
      font-size: 14px;
      border-radius: 4px;
      margin-bottom: 15px;
    }

    .form {
      padding: 10px 0 5px 0;

      .row {
        > div {
          .form-group {
            margin-bottom: 0;

            select {
              font-size: $px14;
              height: $formHeightLg;
            }

            input {
              display: block;
              height: $formHeightLg;
              font-size: $px14;
              border-color: #ddd;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .prompt {
            height: 100%;
            position: relative;
            padding-top: 32px;

            label {
              position: absolute;
              top: 0;
              left: 0;
            }

            .btn {
              position: absolute;
              top: 0;
              right: 0;
              font-size: 10px;
              background: $colorSecondary;
              color: #fff;
              padding: $px2 $px4;
              margin-top: -1px;
            }

            > .wrapper {
              height: 100%;
              position: relative;

              textarea {
                position: absolute;
                height: 100%;
                min-height: 200px;
              }
            }
          }

          .image {
            height: 100%;
            text-align: center;
            padding: 30px 0 15px 0;
            position: relative;

            img {
              &.meeting {
                width: 100%;
                max-width: 355px;
              }

              &.robot {
                height: 110px;
                position: absolute;
                bottom: -60px;
                right: 50%;
                margin-right: -52px;
                transition: bottom 0.9s;
                animation: move 2s infinite ease-in-out;
              }
            }

            div {
              padding-top: 20px;
            }
          }
        }
      }
    }
  }

  .act {
    position: sticky;
    bottom: 0;

    .btn {
      padding: $px15;
      color: #fff;
      height: $px56;
      border-radius: 0;
    }
  }

  @media(max-width: 991px) {
    > .wrapper .form .row > div {
      .prompt {
        height: auto;
        padding-top: 1.5rem;

        label {
          position: static;
        }

        > .wrapper textarea {
          position: static;
        }
      }
    }
  }

  @keyframes move {
    0% {
      bottom: -10px;
    }

    50% {
      bottom: -20px;
    }

    100% {
      bottom: -10px;
    }
  }
}
</style>